import http from "@/utils/request";

export default {
  list: function (params) {
    const url = `/orders`;
    return http.get(url, { data: params });
  },
  add: function (data) {
    const url = `/orders`;
    return http.post(url, data);
  },
  save: function (data) {
    const url = `/orders/${data.id}`;
    return http.put(url, data);
  },
	external: function (data) {
    const url = `/orders/${data.id}/external`;
    return http.get(url, data);
  },
  hospital: function (data) {
    const url = `/orders/${data.id}/hospital`;
    return http.put(url, data);
  },
  show: function (id) {
    const url = `/orders/${id}`;
    return http.get(url);
  },
  create: function (params) {
    const url = `/orders/create`;
    return http.get(url, { data: params });
  },
  options: function (data) {
    const url = `/orders/options`;
    return http.get(url, { data });
  },
  editInfo: function (id) {
    const url = `/orders/${id}/edit`;
    return http.get(url);
  },
};
