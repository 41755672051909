import axios from "axios";
import { Toast } from "vant";
import sysConfig from "@/config";
import tool from "@/utils/tool";
import router from "@/router";
let loading = null;
axios.defaults.baseURL = sysConfig.API_URL;
axios.defaults.timeout = sysConfig.timeout;

// HTTP request 拦截器
axios.interceptors.request.use(
  (config) => {
    loading = Toast.loading("加载中");
    let token = tool.data.get(sysConfig.tokenField);
    if (token) {
      config.headers[sysConfig.tokenField] = sysConfig.tokenPrefix + token;
    }
    if (config.method == "get") {
      config.params = config.params || {};
      config.params["_"] = new Date().getTime();
    }
    Object.assign(config.headers, sysConfig.headers);
    return config;
  },
  (error) => {
    if (loading) {
      loading.close();
    }
    return Promise.reject(error);
  }
);

// HTTP response 拦截器
axios.interceptors.response.use(
  (response) => {
    if (loading) {
      loading.close();
    }
    return response;
  },
  (error) => {
    if (loading) {
      loading.close();
    }

    if (error.response) {
      switch (error.response.status) {
        case 404:
          Toast.fail("404页面不存在");
          break;
        case 500:
          Toast.fail("服务器发生错误！");
          break;
        case 401:
          tool.data.clear();
          router.push("/");
          break;
        default:
          Toast.fail(
            error.response?.data?.message ||
              `Status:${error.response.status}，未知错误！`
          );
          break;
      }
    } else {
      Toast.fail("请求服务器无响应！");
    }

    return Promise.reject(error.response);
  }
);
var http = {
  /** get 请求
   * @param  {接口地址} url
   * @param  {请求参数} params
   * @param  {参数} config
   */
  get: function (url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: url,
        params: params,
        ...config,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /** post 请求
   * @param  {接口地址} url
   * @param  {请求参数} data
   * @param  {参数} config
   */
  post: function (url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: url,
        data: { data: data },
        ...config,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /** put 请求
   * @param  {接口地址} url
   * @param  {请求参数} data
   * @param  {参数} config
   */
  put: function (url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: "put",
        url: url,
        data: { data: data },
        ...config,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /** patch 请求
   * @param  {接口地址} url
   * @param  {请求参数} data
   * @param  {参数} config
   */
  patch: function (url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: "patch",
        url: url,
        data: { data: data },
        ...config,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /** delete 请求
   * @param  {接口地址} url
   * @param  {请求参数} data
   * @param  {参数} config
   */
  delete: function (url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: "delete",
        url: url,
        data: { data: data },
        ...config,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default http;
