import http from "@/utils/request";

export default {
  bind: function (data) {
    const url = `/auth/relation`;
    return http.patch(url, data);
  },
  logout: function () {
    const url = "/logout";
    return http.patch(url);
  },
  password: function (data) {
    const url = "/auth/password";
    return http.patch(url, data);
  },
};
