export default {
  appName: "CRM销售订单系统",
  tokenPrefix: "Bearer ",
  tokenField: "Authorization",
  userField: "__u__",
  homePage: "/",
  timeout: 50000,
  headers: {}, //追加header,axios request header
  //接口地址
  API_URL:
    process.env.NODE_ENV === "development" &&
    process.env.VUE_APP_PROXY === "true"
      ? process.env.VUE_APP_API_PREFIX
      : process.env.VUE_APP_API_BASEURL,
  // 服务端授权接口地址
  authUrl: process.env.VUE_APP_API_BASEURL + "auth",
};
