import { createRouter, createWebHistory } from "vue-router";
import tool from "../utils/tool";
import config from "../config";

const routes = [
  {
    path: "/",
    name: "首页",
    component: () => import("@/components/home"),
  },
  {
    path: "/order",
    name: "我的订单",
    component: () => import("@/components/orders/index"),
  },
  {
    path: "/order/detail",
    name: "订单详情",
    component: () => import("@/components/orders/detail"),
  },
  {
    path: "/order/external/save",
    name: "外部下单",
    component: () => import("@/components/orders/save"),
  },
  {
    path: "/order/operation/save",
    name: "直销下单",
    component: () => import("@/components/orders/save"),
  },
  {
    path: "/order/distribution/save",
    name: "分销下单",
    component: () => import("@/components/orders/save"),
  },
  {
    path: "/login",
    name: "登录",
    component: () => import("@/components/Login"),
  },
  {
    path: "/my",
    name: "个人中心",
    component: () => import("@/components/my/index"),
  },
  {
    path: "/my/password",
    name: "修改密码",
    component: () => import("@/components/my/password"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // 带登录信息判断
  const queryStr = to?.query?.data || null;
  if (queryStr) {
    try {
      const query = JSON.parse(queryStr) || {};
      const params = query?.data || {};
      if (params && params.is_bind) {
        tool.data.set(config["tokenField"], params.token);
        tool.data.set(config["userField"], params.user);
      }
    } catch (e) {
      // TODO
    }
  }
  const hasLogin =
    tool.data.get(config["tokenField"]) && tool.data.get(config["userField"]);
  // 已登录
  if (!hasLogin) {
    if (to.path != "/login") {
      tool.data.remove(config["tokenField"]);
      tool.data.remove(config["userField"]);
      const toPath = to.fullPath || to.path || "/";
      return router.push("/login?to=" + toPath);
    }
  } else {
    if (to.path == "/login") {
      return router.push(config["homePage"] || "/");
    }
  }
  document.title = to.name || "";
  return next();
});

export default router;
