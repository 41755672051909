const tool = {};

/* localStorage */
tool.data = {
  set(key, data, datetime = 0) {
    let cacheValue = {
      content: data,
      datetime:
        parseInt(datetime) === 0
          ? 0
          : new Date().getTime() + parseInt(datetime) * 1000,
    };
    return localStorage.setItem(key, JSON.stringify(cacheValue));
  },
  get(key) {
    try {
      const value = JSON.parse(localStorage.getItem(key));
      if (value) {
        let nowTime = new Date().getTime();
        if (nowTime > value.datetime && value.datetime != 0) {
          localStorage.removeItem(key);
          return null;
        }
        return value.content;
      }
      return null;
    } catch (err) {
      return null;
    }
  },
  remove(key) {
    return localStorage.removeItem(key);
  },
  clear() {
    return localStorage.clear();
  },
};

tool.dateFormat = function (date, fmt = "yyyy-MM-dd hh:mm:ss") {
  date = new Date(date);
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
};

export default tool;
