<template>
  <div>
    <router-view></router-view>
    <van-tabbar v-show="showTabbar" v-model="active">
      <van-tabbar-item name="home" icon="home-o" to="/">首页</van-tabbar-item>
      <van-tabbar-item name="order" icon="friends-o" to="/order"
        >订单</van-tabbar-item
      >
      <van-tabbar-item name="my" icon="setting-o" to="/my"
        >我的</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    showTabbar() {
      if (this.$route.path == "/login") {
        return false;
      }
      return this.$TOOL.data.get(this.$CONFIG["tokenField"]) != "";
    },
  },
  watch: {
    $route() {
      this.parseTabbarHeightLight();
    },
  },

  mounted() {
    this.parseTabbarHeightLight();
  },
  data() {
    return {
      active: "",
    };
  },
  methods: {
    parseTabbarHeightLight() {
      const path = this.$route.fullPath;
      if (path == "/") {
        this.active = "home";
      } else if (path.indexOf("/order") > -1) {
        this.active = "order";
      } else if (path.indexOf("/my") > -1) {
        this.active = "my";
      } else {
        this.active = "/home";
      }
    },
  },
};
</script>

<style lang="scss">
@import "./style/app.scss";
</style>